soaApp.directive('validNumber', function() {
	  return {
	    require: '?ngModel',
	    link: function(scope, element, attrs, ngModelCtrl) {
	      if(!ngModelCtrl) {
	        return; 
	      }

	      ngModelCtrl.$parsers.push(function(val) {
	        var clean = val.replace( /[^0-9]+/g, '');
	        if (val !== clean) {
	          ngModelCtrl.$setViewValue(clean);
	          ngModelCtrl.$render();
	        }
	        return clean;
	      });

	      element.bind('keypress', function(event) {
	        if(event.keyCode === 32) {
	          event.preventDefault();
	        }
	      });
	    }
	  };
	});

window.getAllProtocols = () => {
	var retObj;
	$.ajax({
		url:'/json/protocol/list/',
		async:false,
		success: function(data,status) {
			retObj = data['aaData'];
		}
    });
	return retObj;
}

function moneyDec(str) {
	str = parseInt(str);
	return "$"+str.toFixed(2);
}

window.generateUUID = () => {
    var d = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = (d + Math.random()*16)%16 | 0;
        d = Math.floor(d/16);
        return (c=='x' ? r : (r&0x3|0x8)).toString(16);
    });
    return uuid;
};

window.generateObjectId = () => {
	 var objectId = new ObjectId();
	 return objectId.toString();
}

window.date_from_oid = (objectId) => {
	if (!objectId) return;
	thisDate =  new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
	return parseInt(thisDate.getMonth())+1 + '/' + thisDate.getDay() + '/' + thisDate.getFullYear();
}

window.money = (str) => {
	if (str) return '$'+str;
	else return '';
}

window.mysql_to_date = (dateStr) => {
	if (dateStr) {
    	dateSplit = dateStr.replace(/\s.+?$/,'').split('-');
    	date = dateSplit[1]+'/'+dateSplit[2]+'/'+dateSplit[0];
    	if (date == '00/00/0000') 
    		return '';
    	else
    		return date;
    	
	} else {
		return '';
	}
}

window.listHeight = () => {
	return parseInt(($(window).height()-260)/30);
}

window.editorHeight =() => {
	return parseInt(($(window).height()-240));
}

(function ($, window) {
$.fn.contextMenu = function (settings) {

    return this.each(function () {

        // Open context menu
        $(this).on("contextmenu", function (e) {
            //open menu
            $(settings.menuSelector)
                .data("invokedOn", $(e.target))
                .show()
                .css({
                    position: "absolute",
                    left: getLeftLocation(e),
                    top: getTopLocation(e)
                })
                .off('click')
                .on('click', function (e) {
                    $(this).hide();
            
                    var $invokedOn = $(this).data("invokedOn");
                    var $selectedMenu = $(e.target);
                    
                    settings.menuSelected.call(this, $invokedOn, $selectedMenu);
            });
            
            return false;
        });

        //make sure menu closes on any click
        $(document).click(function () {
            $(settings.menuSelector).hide();
        });
    });

    function getLeftLocation(e) {
        var mouseWidth = e.pageX;
        var pageWidth = $(window).width();
        var menuWidth = $(settings.menuSelector).width();
        
        // opening menu would pass the side of the page
        if (mouseWidth + menuWidth > pageWidth &&
            menuWidth < mouseWidth) {
            return mouseWidth - menuWidth;
        } 
        return mouseWidth;
    }        
    
    function getTopLocation(e) {
        var mouseHeight = e.pageY;
        var pageHeight = $(window).height();
        var menuHeight = $(settings.menuSelector).height();

        // opening menu would pass the bottom of the page
        if (mouseHeight + menuHeight > pageHeight &&
            menuHeight < mouseHeight) {
            return mouseHeight - menuHeight;
        } 
        return mouseHeight;
    }

};
})(jQuery, window);

window.rowDragHelper = function(e, ui) {
	ui.children().each(function() {
		$(this).width($(this).width());
	});
	return ui;
};

function uniq(a) {
    return a.sort().filter(function(item, pos) {
        return item != '' && (!pos || item != a[pos - 1]);
    })
}

function escapeRegExp(string){
    return string.replace(/([.*+?^${}()|\[\]\/\\])/g, "\\$1");
}

/*Array.prototype.move = function (old_index, new_index) {
    if (new_index >= this.length) {
        var k = new_index - this.length;
        while ((k--) + 1) {
            this.push(undefined);
        }
    }
    this.splice(new_index, 0, this.splice(old_index, 1)[0]);
    //return this; // for testing purposes
};*/

function findDifferences(objectA, objectB) {
	   var propertyChanges = [];
	   var objectGraphPath = ["this"];
	   (function(a, b) {
	      if(a.constructor == Array) {
	         // BIG assumptions here: That both arrays are same length, that
	         // the members of those arrays are _essentially_ the same, and 
	         // that those array members are in the same order...
	         for(var i = 0; i < a.length; i++) {
	            objectGraphPath.push("[" + i.toString() + "]");
	            arguments.callee(a[i], b[i]);
	            objectGraphPath.pop();
	         }
	      } else if(a.constructor == Object || (a.constructor != Number && 
	                a.constructor != String && a.constructor != Date && 
	                a.constructor != RegExp && a.constructor != Function &&
	                a.constructor != Boolean)) {
	         // we can safely assume that the objects have the 
	         // same property lists, else why compare them?
	         for(var property in a) {
	            objectGraphPath.push(("." + property));
	            if(a[property].constructor != Function) {
	               arguments.callee(a[property], b[property]);
	            }
	            objectGraphPath.pop();
	         }
	      } else if(a.constructor != Function) { // filter out functions
	         if(a != b) {
	            propertyChanges.push({ "Property": objectGraphPath.join(""), "ObjectA": a, "ObjectB": b });
	         }
	      }
	   })(objectA, objectB);
	   return propertyChanges;
	}
	
window.CleanPastedHTML = (input) => {
  
  // 1. remove line breaks / Mso classes
  var stringStripper = /(\n|\r| class=(")?Mso[a-zA-Z]+(")?)/g;
  var output = input.replace(stringStripper, ' ');
  // 2. strip Word generated HTML comments
  var commentSripper = new RegExp('<!--(.*?)-->','g');
  var output = output.replace(commentSripper, '');
  var tagStripper = new RegExp('<(/)*(xml|span|class|meta|link|\\?xml:|st1:|o:|font)(.*?)>','gi');
  // 3. remove tags leave content if any
  output = output.replace(tagStripper, '');
  // 4. Remove everything in between and including tags '<style(.)style(.)>'
  var badTags = ['p','script','applet','embed','noframes','noscript','style'];
  for (var i=0; i< badTags.length; i++) {
    tagStripper = new RegExp('<'+badTags[i]+'.*?'+badTags[i]+'(.*?)>', 'gi');
    output = output.replace(tagStripper, '');
  }
  // 5. remove attributes ' style="..."'
  var badAttributes = ['start','class','style'];
  for (var i=0; i< badAttributes.length; i++) {
    var attributeStripper = new RegExp(' ' + badAttributes[i] + '="(.*?)"','gi');
    output = output.replace(attributeStripper, '');
  }
  // 6. remove bad CSS style declarations
  var badCSS = ['page-break-after','line-height','font-size','font-family','text-align','vertical-align'];
  for (var i=0; i< badCSS.length; i++) {
    var cssStripper = new RegExp(badCSS[i] + ':(.*?)','gim');
    output = output.replace(cssStripper, '');
  }
  return output;
}

/**
 * ng-context-menu - v1.0.1 - An AngularJS directive to display a context menu
 * when a right-click event is triggered
 *
 * @author Ian Kennington Walter (http://ianvonwalter.com)
 */
(function(angular) {
  'use strict';

  angular
    .module('ng-context-menu', [])
    .factory('ContextMenuService', function() {
      return {
        element: null,
        menuElement: null
      };
    })
    .directive('contextMenu', [
      '$document',
      'ContextMenuService',
      function($document, ContextMenuService) {
        return {
          restrict: 'A',
          scope: {
            'callback': '&contextMenu',
            'disabled': '&contextMenuDisabled',
            'closeCallback': '&contextMenuClose'
          },
          link: function($scope, $element, $attrs) {
            var opened = false;

            function open(event, menuElement) {
              menuElement.addClass('open');

              var doc = $document[0].documentElement;
              var docLeft = (window.pageXOffset || doc.scrollLeft) -
                  (doc.clientLeft || 0),
                docTop = (window.pageYOffset || doc.scrollTop) -
                  (doc.clientTop || 0),
                elementWidth = menuElement[0].scrollWidth,
                elementHeight = menuElement[0].scrollHeight;
              var docWidth = doc.clientWidth + docLeft,
                docHeight = doc.clientHeight + docTop,
                totalWidth = elementWidth + event.pageX,
                totalHeight = elementHeight + event.pageY,
                left = Math.max(event.pageX - docLeft, 0),
                top = Math.max(event.pageY - docTop, 0);

              if (totalWidth > docWidth) {
                left = left - (totalWidth - docWidth);
              }

              if (totalHeight > docHeight) {
                top = top - (totalHeight - docHeight);
              }

              menuElement.css('top', top + 'px');
              menuElement.css('left', left + 'px');
              opened = true;
            }

            function close(menuElement) {
              menuElement.removeClass('open');

              if (opened) {
                $scope.closeCallback();
              }

              opened = false;
            }

            $element.bind('contextmenu', function(event) {
              if (!$scope.disabled()) {
                if (ContextMenuService.menuElement !== null) {
                  close(ContextMenuService.menuElement);
                }
                ContextMenuService.menuElement = angular.element(
                  document.getElementById($attrs.target)
                );
                ContextMenuService.element = event.target;
                //console.log('set', ContextMenuService.element);

                event.preventDefault();
                event.stopPropagation();
                $scope.$apply(function() {
                  $scope.callback({ $event: event });
                });
                $scope.$apply(function() {
                  open(event, ContextMenuService.menuElement);
                });
              }
            });

            function handleKeyUpEvent(event) {
              //console.log('keyup');
              if (!$scope.disabled() && opened && event.keyCode === 27) {
                $scope.$apply(function() {
                  close(ContextMenuService.menuElement);
                });
              }
            }

            function handleClickEvent(event) {
              if (!$scope.disabled() &&
                opened &&
                (event.button !== 2 ||
                  event.target !== ContextMenuService.element)) {
                $scope.$apply(function() {
                  close(ContextMenuService.menuElement);
                });
              }
            }

            $document.bind('keyup', handleKeyUpEvent);
            // Firefox treats a right-click as a click and a contextmenu event
            // while other browsers just treat it as a contextmenu event
            //$document.bind('click', handleClickEvent);
            $document.bind('contextmenu', handleClickEvent);

            $scope.$on('$destroy', function() {
              //console.log('destroy');
              $document.unbind('keyup', handleKeyUpEvent);
              $document.unbind('click', handleClickEvent);
              $document.unbind('contextmenu', handleClickEvent);
            });
          }
        };
      }
    ]);
})(angular);