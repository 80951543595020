//protocol controllers
soaApp.controller("protocolListController",function($scope,$http,$route,$routeParams,$location,$rootScope) {
	$rootScope.section = 'protocol';
	$scope.status_id = $route.current.params['status_id'];
	
	$(document).ready(function() {
		gListTable = $('#protocolTable').dataTable({
			"iDisplayLength":listHeight(),
			"sAjaxSource": '/json/protocol/list/'+($scope.status_id ? $scope.status_id : ''),
			"aoColumns": [
	              { "mData": "protocol_title" },
	              { "mData": "phase" },
	              { "mData": "therapeutic_area" },
	              { "mData": "indication" },
	              { "mData": "molecule" },
	              { "mData": "study_id" },
	              { "mData": "author" },
	              { "mData": "created", mRender:mysql_to_date },
	              { "mData": "last_mod", mRender:mysql_to_date },
	              { "mData": "status"}
			 ],
	        "aaSorting": [[ 1, "desc" ]],
	    	"oLanguage": dataTableLangDefaults(),
			"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
				 $(nRow).unbind('click').bind('click',function () { 
					 $rootScope.objId = aData._id.$oid;
					 $rootScope.nRow = nRow;
					 $rootScope.openModal('protocolDetailController');
					 //location.href="#/protocol/detail/"+aData._id.$oid;
				 }).bind('contextmenu',function(e) {
				 	 //TODO
				 });
			}
		});
	});
});

soaApp.controller("protocolDetailController",function($scope,$http,$route,$routeParams,$location,$rootScope,getNavCounts) {
	$rootScope.loading = true;
	$rootScope.section = 'protocol';
	$scope._id = $rootScope.objId;
	$rootScope._id = $scope._id;
	
	$http.get('/json/protocol/'+$scope._id).
	then(({data} = response) => {
      $scope.modelStatic = true;
      $scope.protocol = data;
      $rootScope.protocol = $scope.protocol;
      $scope.cache = angular.copy($scope.protocol);
      $scope.$watch('protocol',function(newVal,oldVal) { if (newVal != oldVal) $scope.modelStatic = false; },true);
      $rootScope.loading = false;
      $rootScope.modalTitle = 'Edit Protocol';
  	  $scope.modalBodyTemplate = 'editProtocol';
    });
    
	$("#therapeutic_area").typeahead({ source:therapeuticAreas });
	
	
	$scope.close = function(result) {
		  if (result == "save") {
			  var thisModel = this.protocol;
				//delete thisModel.author_id;
				$http({
		            method : 'POST',
		            url : '/json/protocol/save/'+$scope._id,
		            data : thisModel
		        }).then(({data} = response) => {
		        	$('#protocolTable').DataTable().row($rootScope.nRow).data(data.aaData[0]);
		        	$('#protocolTable').DataTable().draw(false);
		        	$('.modal').modal('hide');
		 		});

		  }
		  if (result == "cancel") {
			  $('.modal').modal('hide');  
		  }
	  };
	
	$scope.cancelSaveProtocol = function() {
		$scope.protocol = $scope.cache;
		$rootScope.protocol = $scope.protocol;
		$scope.cache = angular.copy($scope.protocol);
		$scope.modelStatic = true;
	};
	
	$scope.deleteProtocol = function() {
		if (confirm("Are you sure you want to delete this protocol? You cannot undo this change.")) {
			$http({
	            method : 'GET',
	            url : '/json/protocol/remove/'+$scope._id,
	        }).then(() => {
	        	getNavCounts.init();
	        	$('#protocolTable').DataTable().ajax.reload();
	        	$('.modal').modal('hide');
	        });
		}
		
	}
	
	$scope.saveProtocol = function() {
		var thisModel = this.protocol;
		delete thisModel.author_id;
		$http({
            method : 'POST',
            url : '/json/protocol/save/'+$scope._id,
            data : thisModel
        }).then(({data} = response) => {
        	let thisTable = $('#protocolTable').DataTable();
        	thisTable.row($rootScope.nRow).data(data[0]);
        	thisTable.DataTable().draw(false)
        	$('.modal').modal('hide');
        	$scope.cache = angular.copy($scope.protocol);
        	$scope.modelStatic = true;
 		});
	};
	
	$scope.openDocument = function(fileName,fileURL,fileType) {
		if (fileType == "upload") {
			window.location.href =  $location.protocol()+"://"+$location.host()+":5000/doc_upload/"+fileName;
		} else {
			window.open(fileURL);
		}
	};
	
	$scope.editDocument = function(fileName,fileType) {
		$rootScope.openModal('editProtocolLink');
	};
	
	
});

soaApp.controller('addProtocol', function ($scope, $rootScope, $http, close,getNavCounts) {
	
	$rootScope.modalTitle = 'Add New Protocol';
	$scope.modalBodyTemplate = 'addProtocol';
	$scope.close = function(result) {
	 	
		var fileData;
	 	var thisModel 	= this.protocol_new;
	 	var thisHttp	= $http;
		
	 	if (thisModel.protocol_file_type == 'upload') {
		 	var f = document.getElementById('file').files[0];
		 	var filename = f.name;
		}
	
	 	thisModel.protocol_filename = filename;
	 	
	 	if (result == 'save') {
			$rootScope.loading = true;
			
			$http({
	            method : 'POST',
	            url : '/insert/protocol',
	            data: thisModel
	        }).then(() => {
        		$('#protocolTable').DataTable().ajax.reload();
	        	$('.modal').modal('hide');
	        	$scope.loading = false;
	        	getNavCounts.init();
	        	$rootScope.protocols = getAllProtocols();
	        });
	 	} else {
	 		$('.modal').modal('hide');
	 	}
	};	
});

soaApp.controller('editProtocolLink', function ($scope, $rootScope, $http, close, fileUpload) {
	$scope.protocol = $rootScope.protocol;
	$rootScope.modalTitle = 'Edit Protocol Document Link';
	$scope.modalBodyTemplate = 'editProtocolLink';
	$scope.fileChanged = false;

	$scope.fileNameChanged = function() {
		$('#replaceFileBtn').removeAttr('disabled');
	};
	
	$scope.close = function(result) {
		if (result == 'save') {
			var thisModel = this.protocol;
			$http({
	            method : 'POST',
	            url : '/json/protocol/save/'+$rootScope._id,
	            data : thisModel
	        }).data(({data} = response) => {
	        	$('#protocolTable').DataTable().row($rootScope.nRow).data(data[0]);
	        	$('#protocolTable').DataTable().draw(false)
	        	$('.modal').modal('hide');
	 		});
		}
	};
	$rootScope.close = $scope.close;
});


soaApp.controller('fileUploadCtrl', ['$scope', '$rootScope', 'fileUpload', function($scope,$rootScope,fileUpload){
	var thisScope = $rootScope;
    $scope.uploadFile = function(){
        var file = $scope.myFile;
        if (!file) alert("Please select a file first.")
        console.log('file is ' + JSON.stringify(file));
        var uploadUrl = "/upload";
        fileUpload.uploadFileToUrl(file, uploadUrl,thisScope);
    };
    
}]);