import "./sass/bootstrap.css";
import "./sass/summernote.css";
import "./sass/jquery-ui.css";
import "./sass/font-awesome.min.css";

window.$ = window.jQuery = require('jquery');
require("jquery-ui");
require('jquery-ui/ui/widgets/draggable.js');
require('jquery-ui/ui/widgets/sortable.js');
window.angular = require('angular');
window.angularModalService = require('angular-modal-service');
require('moment'); 
window.d3 = require('d3'); 
require('angular-route');
require('angular-sanitize');
require('angular-ui-sortable');
require('summernote');
require('angular-summernote/src/angular-summernote');
//require('floatthead');
require('bootstrap3');
require('bootstrap-3-typeahead');
import daterangepicker from '../node_modules/daterangepicker';
window.DataTable = require( 'datatables.net' );

//hack for modal escape
$(window).bind('keydown',function(e){
	if (e.keyCode == 27) {
		$('.modal, .modal-backdrop').remove();
		$('body').removeClass('modal-open');
	}
});

window.soaApp = angular.module("SOAApp", ['ngRoute','ng-context-menu','angularModalService','ngSanitize','summernote','angularCharts']).config(['$interpolateProvider','$httpProvider', function ($interpolateProvider, $httpProvider) {
	$interpolateProvider.startSymbol('[{[');
	$interpolateProvider.endSymbol(']}]');
	
	$httpProvider.interceptors.push(function($q, $rootScope) {
	  return {
	     'request': function(config) {
	    	$rootScope.loading = true;
	        return config;
	     },
	     'response': function(response) {
	    	 $rootScope.loading = false;
	    	 return response;
	     },
	     'responseError': function(response) {	
	    	 $rootScope.loading = false;
	    	 return response;
	     }
	  }
	});	
}]);

soaApp.config(['$locationProvider', function($locationProvider) {
  $locationProvider.hashPrefix('');
}]);

soaApp.directive("contenteditable", function() {
  return {
    restrict: "A",
    require: "ngModel",
    link: function(scope, element, attrs, ngModel) {

      function read() {
        ngModel.$setViewValue(element.html());
      }

      ngModel.$render = function() {
    	element.html(ngModel.$viewValue || "");
      };

      element.bind("keyup change", function() {
        scope.$apply(read);
      });
    }
  };
});

soaApp.directive('fileModel', ['$parse', function ($parse) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            var model = $parse(attrs.fileModel);
            var modelSetter = model.assign;
            
            element.bind('change', function(){
                scope.$apply(function(){
                    modelSetter(scope, element[0].files[0]);
                });
            });
        }
    };
}]);



soaApp.directive('ngRightClick', function($parse) {
    return function(scope, element, attrs) {
        var fn = $parse(attrs.ngRightClick);
        element.bind('contextmenu', function(event) {
            scope.$apply(function() {
                event.preventDefault();
                fn(scope, {$event:event});
            });
        });
    };
});

soaApp.directive('ngFormDisable', function() {
	 return function link(scope, element, attrs) {
		 var enabled = true;
		 scope.$watch(attrs.ngFormDisable, function(value) {
			 var enabled = value.enabled;
			 element.find('form').filter(function() {
				 $(this).find('.form-control').prop('disabled',!enabled);
				 $(this).find('.note-editable').prop('contenteditable',enabled);
			 }); 
			
		 });
	 }
});


soaApp.service('fileUpload', ['$http', '$rootScope', function ($http,$rootScope) {
	this.uploadFileToUrl = function(file, uploadUrl, thisScope, isNew){
		var isNew = isNew;
	    var fd = new FormData();
	    fd.append('myFile', file);
        $http.post(uploadUrl, fd, {
            transformRequest: angular.identity,
            headers: {'Content-Type': undefined}
        })
        .then(() => {
        	if (isNew) 
        		protScope = thisScope.protocol_new;
        	else 
        		protScope = thisScope.protocol
        	
        	protScope.protocol_filename = file.name;
        	protScope.protocol.protocol_file_type = 'upload';
        	thisScope.close('save');
        },(error) => {}
        );
    }
}]);

soaApp.service('assessments', ['$http', '$rootScope', function ($http,$rootScope) {
	this.init = function(){
		$http({
	        method : 'GET',
	        url : '/json/assessment/list/true'
	    }).then((response) => {
	    	var retData = response.data['aaData'];
	    	for(let assessment in retData) {
	    		if (retData[assessment]._id != undefined) {
		    		thisId = retData[assessment]._id.$oid;
		    		retData[assessment].assessment_id = thisId;
		    		retData[assessment]._id = '';
	    		}
	    	}
	    	$rootScope.assessments 	= retData;
	    });
	};
}]);

soaApp.service('checkSaveSoa', ['$http', '$rootScope', function ($http,$rootScope,$route) {
	this.checkSave = function(){
		/*if ($rootScope.isDraft) {
			var soa_id = $rootScope.soa_id;
			if (confirm("This SOA has been modified. Would you like to save it before leaving this view?")) {
				var thisModel = $rootScope.soa;
				$http({
		            method : 'POST',
		            url : '/json/soa/save/'+soa_id,
		            data : thisModel
		        }).then((response) => {
		        	$rootScope.isDraft = false;
		 		});
			} 
		}*/
	};
}]);
	
soaApp.service('getNavCounts', ['$http', '$rootScope', function ($http,$rootScope) {
	this.init = function(){
		$http.get('/json/navcounts').then((response) => {
			$rootScope.counts = response.data;
		  },(error) => {
		    // TODO: logout, session timeout
		  });
	};
}]);

soaApp.service('cptTypeahead', ['$http', '$rootScope', function ($http,$rootScope) {
	this.init = function(){
		var updateDollars = false;
		setTimeout(function() { 
			var map = {};
		  	$(".cpt_code").typeahead({ 
		  		source:function(term,process) {
		  			var agents = [];
		            return ($.getJSON('/json/cpt/typeahead', { term: term }, function (data) {
		            	$.each(data, function (i, item) {
		                	map[item.cpt_code+': '+item.procedure] = item;
		                    agents.push(item.cpt_code+': '+item.procedure);
		                }); 
		                process(agents);
		            }));
		  			
		  		},updater:function(item) {
		  			updateDollars = true;
		  			$rootScope.cpt = map[item];
		  			return item;
		  		}
		  	});
			},500);
			$rootScope.$watch('cpt.dollars',function(newValue, oldValue){
				if (newValue != oldValue) {
					if (!$rootScope.assessment) $rootScope.assessment = [{}];
					$rootScope.assessment.assessment_cost = newValue;
					$rootScope.assessment.cpt_code = $rootScope.cpt.cpt_code;
					$rootScope.assessment.cpt_code_lookup = null;
					updateDollars = false;
				}
				
				$(".cpt_code").val('');
			});
		
		}
		
}]);

soaApp.config(function($routeProvider, $locationProvider) {
	$routeProvider
		
		//home
		.when('/:tab?', {
			templateUrl : '/home/',
			controller  : 'homeController'
		})
		
		//protocol
		.when('/protocol/list/:status_id?', {
			templateUrl : '/protocol/list/',
			controller  : 'protocolListController'
		})
		.when('/protocol/detail/:_id', {
			templateUrl : '/protocol/detail/',
			controller  : 'protocolDetailController'
		})
		
		//soa
		.when('/soa/list/:status_id?', {
			templateUrl : '/soa/list/',
			controller  : 'soaListController'
		})
		.when('/soa/detail/:_id?', {
			templateUrl : '/soa/detail/',
			controller  : 'soaDetailController'
		})
		
		//report
		.when('/report/list/:_type?/', {
			templateUrl : '/report/list/',
			controller  : 'reportMainController'
		})
		
		//admin assessment
		.when('/manage/list/:tab?', {
			templateUrl : '/manage/list/',
			controller  : 'assessmentListController'
		})
		.when('/assessment/detail/:_id', {
			templateUrl : '/assessment/detail/:id',
			controller  : 'assessmentDetailController'
		})
		
}).run(function($rootScope, $location,checkSaveSoa) {
    $rootScope.$on( "$routeChangeStart", function(event, next, current) {
        if (next && current && next.loadedTemplateUrl != current.loadedTemplateUrl && current.loadedTemplateUrl == "/soa/detail/") {
        	checkSaveSoa.checkSave();
        	console.log("Check save");
        }
    });
});

soaApp.controller('homeChart',function($scope,$timeout,$http) {
	var thisTooltip;
	var labels = ['Primary Efficacy', 'Secondary', 'Safety', 'Exploratory'];
	$scope.config = {
		tooltips: true,
	    labels: true,
	    mouseover: function(d) {
	        if (d.data) {
	        	tooltipText = d.data.x;
	        } else {
	        	tooltipText = labels[d.s]+': $'+d.y;
	        }
	    	$('.ac-tooltip').text(tooltipText);
	    },
	    mouseout: function() {},
	    click: function() {},
	    legend: {
	      display: true,
	      //could be 'left, right'
	      position: 'left',
	      htmlEnabled: true
	    },
	    isAnimate:true,
	    waitForHeightAndWidth:true
	};
	
	
	
	$scope.loadReport = function() {
		
		//$scope.hideChart = true;
		if ($scope.chartType == 'avg_endpoint' || $scope.chartType == 'total_endpoint') {
			
			$scope.config.labelFunction =  function(d) {
				return labels[d.s] + ': $'+d.y;
			};
			
			//$timeout(function() {		
				var soaData = [];
				var endpointValues = [0,0,0,0];
				
				$http.get('/report/avg_endpoint/').then((response) => {
					var count=0;
					const { data } = response;
					for(var i in data) {
		        		let endpointArray = data[i].endpoint_subtotal;
		        		if (endpointArray) {
		        			endpointArray.shift();
		        			for(var j in endpointArray) {
		        				endpointValues[j] += endpointArray[j];	
		        			}
		        			count++;
		        		}
					}
					
					for(var j in endpointValues) {
						if (endpointValues[j] > 0) {
							if ($scope.chartType == 'avg_endpoint') {
								endpointValues[j] = Math.round(endpointValues[j]/count);
								$scope.config.title = "Average Endpoint Cost ("+count+" total SOAs)";
							} else {
								$scope.config.title = "Total Endpoint Cost ("+count+" total SOAs)";
							}
							soaData.push(endpointValues[j]);
							//soaData.push({'x':labels[j]+': $'+endpointValues[j],'y':[endpointValues[j]]});
						}
					}
					
		        	$scope.data = {
		        			series: labels,
		        			data: [{'x':$scope.config.title,'y':soaData}]
		        	};
		        	$scope.hideChart = false;
		        	//$scope.$apply();
		 		});
			//},100);
		}
		
		if ($scope.chartType == 'avg_assessment' || $scope.chartType == 'total_assessment') {
			//$timeout(function() {		
				var soaData = [];
				
				var assessmentValues = [];
				var assessmentLabels = [];
				
				$http.get('/report/avg_assessment/').then((response) => {
					count=0;
					const { data } = response;
					for(var i in data) {
		        		let assessmentArray = data[i].assessment;
		        		let subtotalArray = data[i].subtotal;
		        		if (assessmentArray) {
		        			for(var j in assessmentArray) {
		        				if (!assessmentValues[j]) assessmentValues[j] = 0;
		        				assessmentValues[j] += subtotalArray[j];	
		        				assessmentLabels[j] = assessmentArray[j].assessment_text;	
		        			}
		        			count++;
		        		}
		        		
					}
					
					for(var j in assessmentValues) {
						if (assessmentValues[j] > 0) {
							if ($scope.chartType == 'avg_assessment') {
								assessmentValues[j] = Math.round(assessmentValues[j]/count);
								$scope.config.title = "Average Assessment Cost ("+count+" total SOAs)";
							} else {
								$scope.config.title = "Total Assessment Cost ("+count+" total SOAs)";
							}
							soaData.push({'x':assessmentLabels[j]+': $'+assessmentValues[j],'y':[assessmentValues[j]]});
						}
					}
					
		        	$scope.data = {
		        		series: assessmentLabels,
		        		data: soaData 
		        	};
		        	$scope.hideChart = false;
		        	//$scope.$apply();
		 		});
			//},10);
		}
	};

});

soaApp.controller('MainController', function($scope, $route, $routeParams,$location,$rootScope) {
    $scope.$route = $route;
    $scope.$location = $location;
    $scope.$routeParams = $routeParams;
    $rootScope.section = 'home';
    $rootScope.soa_draft = [];
    
    $rootScope.user = [{}];
    $rootScope.user.userfirst = userfirst;
    $rootScope.user.userlast = userlast;
    
    $('li.dropdown').bind('mouseover',function() {
    	$(this).find('ul.dropdown-menu').removeClass('hide');
    });
    
    $('ul.dropdown-menu').bind('mouseover',function() {
    	$(this).parent().find('a').addClass('navHover');
    }).bind('mouseout',function() {
    	$(this).parent().find('a').removeClass('navHover');
    }).bind('click',function() { $(this).addClass('hide'); });
    
    $scope.openUserDetail = function(user_oid) {
    	$rootScope.user_oid = user_oid;
    	$rootScope.openModal('editUser');
    };
    
    $scope.reportBug = function() {
    	$rootScope.openModal('reportBug');
    };
    
});

//report bug controller
soaApp.controller("reportBug",function($scope,$http,$route,$routeParams,$location,$rootScope) {
	//$scope.bug = [{}];
	$scope.modalBodyTemplate = 'reportBug';
	$scope.modalName = 'reportBug';
	
	$scope.close = function(result) {
		var thisModel = this.bug;
		if (result == 'save') {
			$http({
	            method : 'POST',
	            url : '/insert/bug/',
	            data : thisModel
	        }).then(() => {
	        	$('#reportBug.modal').modal('hide');
	        	alert("Thank you for your report! Expect a response from the development team within 2-3 business days.")
	        	
	 		});
	 	}
	 };
	
});

// home controller
soaApp.controller("homeController",function($scope,$http,$route,$routeParams,$location,$rootScope) {
	$scope.tab = $route.current.params['tab'] ? $route.current.params['tab'] : 'home';
	$rootScope.section = 'home';
	
	
});

soaApp.controller("reportMainController",function($scope,$http,$route,$routeParams,$location,$rootScope,$timeout) {
	$rootScope.isChooser = false;
});


//report controllers
soaApp.controller("comparisonReportController",function($scope,$http,$route,$routeParams,$location,$rootScope,$timeout) {
	
	
	var thisTooltip;
	$scope.labels = ['Primary Efficacy', 'Secondary', 'Safety', 'Exploratory'];
	$scope.config = {
		tooltips: true,
	    labels: $scope.labels,
	    mouseover: function(d) {
	        if (d.data) {
	        	tooltipText = d.data.x;
	        } else {
	        	tooltipText = $scope.labels[d.s]+': $'+d.y;
	        }
	    	$('.ac-tooltip').text(tooltipText);
	    },
	    mouseout: function() {},
	    click: function() {},
	    legend: {
	      display: true,
	      //could be 'left, right'
	      position: 'right',
	      htmlEnabled: true
	    },
	    isAnimate:true,
	    lineLegend: "lineEnd",
	    waitForHeightAndWidth:true,
	    series:[]
	};
	
	$scope.loadReport = function() {
		
		$scope.hideChart = true;
			var soaData = [];
			$scope.config.labelFunction =  function(d) {
				//return $scope.labels[d.s] + ': $'+d.y;
			};
			
			$timeout(function() {		
				var endpointValues = [0,0,0,0];
				
				for(data in $scope.comparison_soas) {
					var count=0;
					endpointArray = $scope.comparison_soas[data]['endpoint_subtotal'];
	        		if (endpointArray) {
	        			if (endpointArray.length == 5) endpointArray.shift();
	        			soaData.push({'x':$scope.comparison_soas[data]['soa_title'],'y':endpointArray});
	        			count++;
	        		}
		 		}
				console.log(soaData);
				$scope.data = {
	        			series: $scope.labels,
	        			data:soaData
				};
				//$scope.data = soaData;
	        	$scope.hideChart = false;
	        	$scope.$apply();
			},10);
	};
	
	$scope.comparison_soas = [{}];
	$rootScope.isChooser = true;
	//$scope.loaded = false;
	$scope.incrementSoas = function() {
		$scope.comparison_soas.push([]);
	};
	
	$scope.removeSoa = function(soaIndex) {
		$scope.comparison_soas.splice(soaIndex,1);
	};
	
	$scope.openChooser = function(soaIndex,isReplace) {
		$scope.isReplace = isReplace;
		$scope.soaIndex = soaIndex;
		$('#soaChooser').modal();
		//$scope.loaded = true;
	};
	
	$scope.isLarge = true;
	$scope.modalBodyTemplate = 'soaChooser';
	$scope.therapeutic_area = [];
	$scope.molecule			= [];
	$scope.indication		= [];
	//$scope.approved_date	= [];
	$scope.version_number	= [];
	$scope.author			= [];
	$scope.boolean_filter	= 'And';
	$scope.noRecords = false;
	$scope.gListTable = null;
	var reportColumns = [
   	     { "mData": "_id.$oid","bVisible": false},
   	     { "mData": "status"},
   	     { "mData" : "approved_date"},
   	     { "mData": "soa_title"},
   	     { "mData": "protocol[0].study_id"},
         { "mData": "protocol[0].protocol_title"},
         { "mData": "protocol[0].molecule" },
         { "mData": "protocol[0].phase"},
         { "mData": "protocol[0].therapeutic_area" },
         { "mData": "protocol[0].indication" },
         { "mData": "version_number"},
         { "mData": "author"},
         { "mData": "total",mRender:money }
   	 ];
	
	$scope.loadReportTable = function() {
		var tableId = 'chooserTable';
		//if ($('#'+tableId).DataTable()) return; //
   		$scope.gListTable = $('#'+tableId).DataTable({
   			"iDisplayLength":listHeight(),
   			"sAjaxSource": '/json/report/data_report/'+($scope.status_id ? $scope.status_id : ''),
   			"aoColumns": eval('reportColumns'),
   	        "aaSorting": [[ 1, "desc" ]],
   	        "bRetrieve":true,
   	        "oLanguage": dataTableLangDefaults(),
   	        "fnInitComplete": function(oSettings, json) {
   	        	jQuery.each(json.aaData,function() {
   	        		var thisProtocol = this.protocol[0];
   	        		$scope.therapeutic_area.push(thisProtocol.therapeutic_area);
   	        		$scope.molecule.push(thisProtocol.molecule);
   	        		$scope.indication.push(thisProtocol.indication);
   	        		$scope.version_number.push(this.version_number);
   	        		//$scope.approved_date.push(this.approved_date);
   	        		$scope.author.push(this.author);
   	        	});
   	        	$scope.therapeutic_area 	= uniq($scope.therapeutic_area);
   	        	$scope.molecule 			= uniq($scope.molecule);
   	        	$scope.indication 			= uniq($scope.indication);
   	        	$scope.version_number 		= uniq($scope.version_number);
   	        	$scope.author 				= uniq($scope.author);
   	        	//$scope.approved_date 		= uniq($scope.approved_date);
   	        	$scope.$apply();
   	        	
   	     	},
   			"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
   				var nData = aData;
   				
   				 $(nRow).unbind('dblclick, click').bind('dblclick, click',function () { 
   					console.log(nData);
   					 $scope.comparison_soas[$scope.soaIndex]['id'] = nData._id.$oid;
   					 $scope.comparison_soas[$scope.soaIndex]['soa_title'] = nData.soa_title;
   					 $scope.comparison_soas[$scope.soaIndex]['endpoint_subtotal'] = nData.endpoint_subtotal;
   					 $scope.$apply();
   					 if (!$scope.isReplace) $scope.incrementSoas();
   					$('#soaChooser').modal('hide');
   					$scope.loadReport();
   				 });
   			},
   			"fnDrawCallback": function( oSettings ) {
   				$timeout(function() {
	   				$scope.noRecords = (oSettings.fnRecordsDisplay() == 0);
	   				$scope.allRecords = (oSettings.fnRecordsDisplay() == oSettings.fnRecordsTotal());
	   				$scope.filterRecords = oSettings.fnRecordsDisplay() ? oSettings.fnRecordsDisplay() : 0;
	   				$scope.$apply();
   				},0);
   		    }	
   		}).draw();
   		$('.daterange').daterangepicker();
   	};
   	
   	$scope.clearFilters = function() {
   		$scope.status_filter = null;
   		$scope.molecule_filter = null;
   		$scope.phase_filter = null;
   		$scope.ta_filter = null;
   		$scope.ind_filter = null;
   		$scope.vn_filter = null;
   		$scope.author_filter = null;
   		//$scope.approved_date_filter = null;
   		$scope.gListTable.columns().search('.*',true).draw();
   	};
   	
   	$scope.filter = function(fieldIndex,fieldValue) {
   		if (fieldValue == '') {
   				fieldValue = '.*';
   				console.log(fieldValue);
   		} else { 
   				fieldValue = escapeRegExp(fieldValue);
   		}
   		console.log(fieldValue);
   		$scope.gListTable.columns(fieldIndex).search("^"+fieldValue+'$',true).draw();
   	};
   	
	$timeout(function () {
		$scope.loadReportTable();
	   	$scope.clearFilters();
	},10);
	
	$scope.close = function(result) {
		
	};

	
});

soaApp.controller("reportController",function($scope,$http,$route,$routeParams,$location,$rootScope,$timeout) {
	$rootScope.section = 'report';
	$scope.type = $route.current.params['type'];
	$scope.therapeutic_area = [];
	$scope.molecule			= [];
	$scope.indication		= [];
	//$scope.approved_date	= [];
	$scope.version_number	= [];
	$scope.author			= [];
	$scope.boolean_filter	= 'And';
	$scope.noRecords = false;
	$scope.gListTable = null;
	var reportColumns = [
   	     { "mData": "_id.$oid","bVisible": false},
   	     { "mData": "status"},
   	     { "mData" : "approved_date"},
   	     { "mData": "soa_title"},
   	     { "mData": "protocol[0].study_id"},
         { "mData": "protocol[0].protocol_title"},
         { "mData": "protocol[0].molecule" },
         { "mData": "protocol[0].phase"},
         { "mData": "protocol[0].therapeutic_area" },
         { "mData": "protocol[0].indication" },
         { "mData": "version_number"},
         { "mData": "author"},
         { "mData": "endpoint_subtotal.1",mRender:money },
         { "mData": "endpoint_subtotal.2",mRender:money },
         { "mData": "endpoint_subtotal.3",mRender:money },
         { "mData": "endpoint_subtotal.4",mRender:money },
         { "mData": "total",mRender:money }
   	 ];

   	$scope.loadReportTable = function() {
   		var tableId ='reportTable';
   		
   		$scope.gListTable = $('#'+tableId).DataTable({
   			"iDisplayLength":listHeight(),
   			"sAjaxSource": '/json/report/data_report/'+($scope.status_id ? $scope.status_id : ''),
   			"aoColumns": eval('reportColumns'),
   	        "aaSorting": [[ 1, "desc" ]],
   	        "oLanguage": dataTableLangDefaults(),
   	        "fnInitComplete": function(oSettings, json) {
   	        	jQuery.each(json.aaData,function() {
   	        		var thisProtocol = this.protocol[0];
   	        		$scope.therapeutic_area.push(thisProtocol.therapeutic_area);
   	        		$scope.molecule.push(thisProtocol.molecule);
   	        		$scope.indication.push(thisProtocol.indication);
   	        		$scope.version_number.push(this.version_number);
   	        		//$scope.approved_date.push(this.approved_date);
   	        		$scope.author.push(this.author);
   	        	});
   	        	$scope.therapeutic_area 	= uniq($scope.therapeutic_area);
   	        	$scope.molecule 			= uniq($scope.molecule);
   	        	$scope.indication 			= uniq($scope.indication);
   	        	$scope.version_number 		= uniq($scope.version_number);
   	        	$scope.author 				= uniq($scope.author);
   	        	//$scope.approved_date 		= uniq($scope.approved_date);
   	        	$scope.$apply();
   	        	
   	     	},
   			"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
   				var nData = aData;
   				 $(nRow).unbind('click').bind('click',function () { 
   					 $rootScope.objId = nData._id.$oid;
   					 $rootScope.nRow = nRow;
   					 location.href="#/soa/detail/"+$rootScope.objId;
   				 }).bind('contextmenu',function(e) {
   				 	
   				 });
   			},
   			"fnDrawCallback": function( oSettings ) {
   				$timeout(function() {
	   				$scope.noRecords = (oSettings.fnRecordsDisplay() == 0);
	   				$scope.allRecords = (oSettings.fnRecordsDisplay() == oSettings.fnRecordsTotal());
	   				$scope.filterRecords = oSettings.fnRecordsDisplay() ? oSettings.fnRecordsDisplay() : 0;
	   				$scope.$apply();
   				},0);
   		    },
   		    "footerCallback": function ( row, data, start, end, display ) {
				var api = this.api(), data;	 
				var intVal = function ( i ) {
					return typeof i === 'string' ? i.replace(/[\$,]/g, '')*1 : typeof i === 'number' ?	i : 0;
				};
	
				$scope.total_primary = api.column( 12,{"filter": "applied"}).data().reduce( function (a, b) { return intVal(a) + intVal(b); },0 );
				$scope.total_secondary = api.column( 13,{"filter": "applied"} ).data().reduce( function (a, b) { return intVal(a) + intVal(b); },0 );
				$scope.total_safety = api.column( 14,{"filter": "applied"} ).data().reduce( function (a, b) { return intVal(a) + intVal(b); },0 );
				$scope.total_exploratory = api.column( 15,{"filter": "applied"} ).data().reduce( function (a, b) { return intVal(a) + intVal(b); },0 );
				$scope.total_all = api.column( 16,{"filter": "applied"} ).data().reduce( function (a, b) { return intVal(a) + intVal(b); },0 );

			},		
   		});
   		$('.daterange').daterangepicker();
   		$scope.isChooser = false;
   	};
   	
   	$scope.clearFilters = function() {
   		$scope.status_filter = null;
   		$scope.molecule_filter = null;
   		$scope.phase_filter = null;
   		$scope.ta_filter = null;
   		$scope.ind_filter = null;
   		$scope.vn_filter = null;
   		$scope.author_filter = null;
   		//$scope.approved_date_filter = null;
   		$scope.gListTable.columns().search('.*',true).draw();
   	};
   	
   	$scope.filter = function(fieldIndex,fieldValue) {
   		if (fieldValue == '') {
   				fieldValue = '.*';
   				console.log(fieldValue);
   		} else { 
   				fieldValue = escapeRegExp(fieldValue);
   		}
   		console.log(fieldValue);
   		$scope.gListTable.columns(fieldIndex).search("^"+fieldValue+'$',true).draw();
   	};
   	
   	$scope.excelExport = function() {
   		url="/export/report/data_report?"
   		if ($scope.status_filter) url += 'status='+$scope.status_filter;
   		if ($scope.ta_filter) url += '&ta='+$scope.ta_filter;
   		if ($scope.molecule_filter) url += '&mol='+$scope.molecule_filter;
   		if ($scope.ind_filter) url += '&ind='+$scope.ind_filter;
   		if ($scope.vn_filter) url += '&vn='+$scope.vn_filter;
   		if ($scope.phase_filter) url += '&phase='+$scope.phase_filter;
   		if ($scope.author_filter) url += '&auth='+$scope.author_filter;
   		url += '&search='+$('#reportTable_filter').find('input[type=search]').val();
   		location.href=url;
   		
   	}

   	$scope.loadReportTable();
   	$scope.clearFilters();
	
});

//admin controllers
soaApp.controller("assessmentListController",function($scope,$http,$route,$routeParams,$location,$rootScope,$window) {
	$rootScope.section = 'soa';
	$scope.tab = $route.current.params['tab'];
	
	
	
	$scope.clearData = function() {
		for (key in $rootScope.assessment) {
			$rootScope.assessment[key] = '';
		}
		for (key in $rootScope.cpt) {
			$rootScope.cpt[key] = '';
		}
		$rootScope.assessmentMode = 'add';
	};
	
	const assessmentColumns = [
	      { "mData": "_id.$oid","bVisible": false},
          { "mData": "assessment_title"},
          { "mData": "cpt_code" },
          { "mData": "assessment_cost",render:money },
          { "mData": "assessment_endpoints_arr",render:ep_1},
          { "mData": "assessment_endpoints_arr",render:ep_2},
          { "mData": "assessment_endpoints_arr",render:ep_3},
          { "mData": "assessment_endpoints_arr",render:ep_4},
          { "mData": "_id.$oid",render:date_from_oid },
          { "mData": "_id.$oid",render:date_from_oid },
          { "mData": "soa_count"}
	 ];

	$scope.loadAssessmentTable = function() {
		const gListTable = $('#assessmentTable').DataTable({
			"iDisplayLength":listHeight(),
			"sAjaxSource": '/json/assessment/list/'+($scope.status_id ? $scope.status_id : ''),
			"aoColumns": eval('assessmentColumns'),
	        "aaSorting": [[ 1, "desc" ]],
	        "oLanguage": dataTableLangDefaults(),
			"fnRowCallback": function( nRow, aData, iDisplayIndex, iDisplayIndexFull ) {
				var nData = aData;
				 $(nRow).unbind('click').bind('click',function () { 
					 $rootScope.objId = nData._id.$oid;
					 $rootScope.nRow = nRow;
					 $rootScope.openModal('editAssessment');
				 }).bind('contextmenu',function(e) {
				 	
				 });
			}
	    
		});
	};
	
	$(document).ready(function() {
		$scope.loadAssessmentTable();
	});
	
	
});

soaApp.controller('addAssessment', ['$scope','$rootScope','$http','close','$window','cptTypeahead','assessments',function ($scope, $rootScope, $http, close, $window, cptTypeahead, assessments) {
	
	//$scope.assessment = [{}];
	$rootScope.modalTitle = 'Create New Assessment';
	$scope.modalBodyTemplate = 'addAssessment';
	$scope.modalName = 'addAssessment';
	
	$scope.button_save = 'Add +';  
	cptTypeahead.init();
	
	$scope.close = function(result) {
		var thisModel = this.new_assessment;
		thisModel.cpt_code = this.cpt.cpt_code;
		delete thisModel._id;
		if (result == 'save') {
			$http({
	            method : 'POST',
	            url : '/insert/assessment/',
	            data : thisModel
	        }).then(() => {
	        	$('#assessmentTable').DataTable().ajax.reload();
	        	assessments.init();
	        	$scope.thisAssessment = thisModel;
	        	$('#addAssessment.modal').modal('hide');
	 		});
	 	}
	 };
	 
	 $scope.options = {
	    toolbar: [
			['style', ['bold', 'italic', 'underline', 'superscript','subscript','clear','ui.sortable']],
			['font', ['strikethrough']],
			['color', ['color']]
	    ]
	  };
	
	 $scope.clearCPT = function() {
		 $rootScope.cpt = [{}];
		 $rootScope.assessment.cpt_code='';
	 }
}]);

soaApp.controller('editAssessment', ['cptTypeahead','$rootScope','$http','$scope','close',function (cptTypeahead, $rootScope, $http, $scope, close) {
	
	$rootScope.loading = true;
	$rootScope.assessmentMode = 'edit';
	$rootScope.cpt = null;
	
	if ($scope.assessment) {
			//$scope.modalBodyTemplate = 'editAssessment';
			//cptTypeahead.init();
	}	
	
	$http({
		method: 'GET',
		url: '/json/assessment/'+$rootScope.objId
	}).then((response) => {
	  const { data } = response;
      $scope.assessment = data;
      if (data.cpt) {
    	  $rootScope.cpt = data.cpt[0];
      }  
      $rootScope.assessment = $scope.assessment;
      $scope.modalTitle = 'Edit Assessment';
      $scope.modalBodyTemplate = 'editAssessment';
      $rootScope.loading = false;
      cptTypeahead.init();
	});
	
	$scope.close = function(result) {
		var thisModel = this.assessment;
		thisModel.cpt = null;
		delete thisModel._id;
		if (result == 'save') {
	 		$http({
	            method : 'POST',
	            url : '/json/assessment/save/'+$rootScope.objId,
	            data : thisModel
	        }).then((response) => {
	            const { data } = response;
	        	if ($rootScope.nRow) {
		        	$('#assessmentTable').DataTable().row($rootScope.nRow).data(thisData[0]);
		        	$('#assessmentTable').DataTable().draw(false);
	        	}
	        	$('.modal').modal('hide');
	 		});
	 	} 
		if (result == 'delete') {
			if (confirm("Are you sure you want to delete this assessment? You cannot undo this change.")) {
				$http({
		            method : 'GET',
		            url : '/json/assessment/remove/'+$rootScope.objId
		        }).then(() => {
		        	$('#assessmentTable').DataTable().row($rootScope.nRow).remove();
		        	$('#assessmentTable').DataTable().draw(false)
		        	$('.modal').modal('hide');
		 		});
			}
		}
	 };	
	 $scope.options = {
	    toolbar: [
			['style', ['bold', 'italic', 'underline', 'superscript','subscript','clear']],
			['font', ['strikethrough']],
			['color', ['color']],
			['table', ['table']],
			['codeview', ['codeview']]
	    ]
	  };
	 $scope.clearCPT = function() {
		 $rootScope.cpt = [{}];
		 $rootScope.assessment.cpt_code='';
	 }
	 
}]);

soaApp.controller("assessmentDetailController",function($scope,$http,$route,$routeParams,$location,$rootScope) {
	$rootScope.section = 'admin';
	$scope._id = $route.current.params['_id'];
});

soaApp.controller("endpointListController",function($scope,$http,$route,$routeParams,$location,$rootScope) {
	$rootScope.section = 'admin';
});

soaApp.controller("endpointDetailController",function($scope,$http,$route,$routeParams,$location,$rootScope) {
	$rootScope.section = 'admin';
	$scope._id = $route.current.params['_id'];
});

soaApp.controller("NavNG", function($scope, $timeout, $http, $location, ModalService,$rootScope,getNavCounts)
		{    
			getNavCounts.init();
			
			//handler for modals
			$rootScope.openModal = function(thisController) {
				ModalService.showModal({
		            templateUrl: 'ngModal.html',
		            controller: thisController
		        }).then(function(modal) {
		        	modal.element.modal({backdrop:'static',keyboard: true});
		        	$('.modal').draggable({
		                handle: ".modal-header"
		            }); 
		        	modal.close.then(function(result) {
		                
		            });
		            
		        });
		    };	
		}
);

(function( $ ) {
  'use strict';

  $.fn.tableToJSON = function(opts) {

    // Set options
    var defaults = {
      ignoreColumns: [],
      onlyColumns: null,
      ignoreHiddenRows: true,
      ignoreEmptyRows: false,
      headings: null,
      allowHTML: false,
      includeRowId : false
    };
    opts = $.extend(defaults, opts);
    
    var notNull = function(value) {
      return value !== undefined && value !== null;
    };

    var ignoredColumn = function(index) {
      if( notNull(opts.onlyColumns) ) {
        return $.inArray(index, opts.onlyColumns) === -1;
      }
      return $.inArray(index, opts.ignoreColumns) !== -1;
    };

    var arraysToHash = function(keys, values) {
      var result = {}, index = 0;
      $.each(values, function(i, value) {
        // when ignoring columns, the header option still starts
        // with the first defined column
        if ( index < keys.length && notNull(value) ) {
          result[ keys[index] ] = value;
          index++;
        }
      });
      return result;
    };

    var cellValues = function(cellIndex, cell) {
      var value, result;
      var override = $(cell).data('override');
      if ( opts.allowHTML ) {
        value = $.trim($(cell).html());
      } else {
        value = $.trim($(cell).text());
      }
      result = notNull(override) ? override : value;
      return result;
    };

    var rowValues = function(row) {
      var result = [];
      var includeRowId = opts.includeRowId;
      var useRowId = (typeof includeRowId === 'boolean') ? includeRowId : (typeof includeRowId === 'string') ? true : false;
      var rowIdName = (typeof includeRowId === 'string') === true ? includeRowId : 'rowId';
      if (useRowId) {
        if (typeof $(row).attr('id') === 'undefined') {
          result.push(rowIdName);
        }
      }
      $(row).children('td,th').each(function(cellIndex, cell) {
        result.push( cellValues(cellIndex, cell) );
      });
      return result;
    };

    var getHeadings = function(table) {
      var firstRow = table.find('tr:first').first();
      return notNull(opts.headings) ? opts.headings : rowValues(firstRow);
    };

    var construct = function(table, headings) {
      var i, j, len, len2, txt, $row, $cell,
        tmpArray = [], cellIndex = 0, result = [];
      table.children('tbody,*').children('tr').each(function(rowIndex, row) {
        if( rowIndex > 0 || notNull(opts.headings) ) {
          var includeRowId = opts.includeRowId;
          var useRowId = (typeof includeRowId === 'boolean') ? includeRowId : (typeof includeRowId === 'string') ? true : false;
          
          $row = $(row);

          var isEmpty = ($row.find('td').length === $row.find('td:empty').length) ? true : false;
          
          if( ( $row.is(':visible') || !opts.ignoreHiddenRows ) && ( !isEmpty || !opts.ignoreEmptyRows ) && ( !$row.data('ignore') || $row.data('ignore') === 'false' ) ) {
            cellIndex = 0;
            if (!tmpArray[rowIndex]) {
              tmpArray[rowIndex] = [];
            }
            if (useRowId) {
              cellIndex = cellIndex + 1;
              if (typeof $row.attr('id') !== 'undefined') {
                tmpArray[rowIndex].push($row.attr('id'));
              } else {
                tmpArray[rowIndex].push('');
              }
            }
        
            $row.children().each(function(){
              $cell = $(this);

              // process rowspans
              if ($cell.filter('[rowspan]').length) {
                len = parseInt( $cell.attr('rowspan'), 10) - 1;
                txt = cellValues(cellIndex, $cell, []);
                for (i = 1; i <= len; i++) {
                  if (!tmpArray[rowIndex + i]) { tmpArray[rowIndex + i] = []; }
                  tmpArray[rowIndex + i][cellIndex] = txt;
                }
              }
              // process colspans
              if ($cell.filter('[colspan]').length) {
                len = parseInt( $cell.attr('colspan'), 10) - 1;
                txt = cellValues(cellIndex, $cell, []);
                for (i = 1; i <= len; i++) {
                  // cell has both col and row spans
                  if ($cell.filter('[rowspan]').length) {
                    len2 = parseInt( $cell.attr('rowspan'), 10);
                    for (j = 0; j < len2; j++) {
                      tmpArray[rowIndex + j][cellIndex + i] = txt;
                    }
                  } else {
                    tmpArray[rowIndex][cellIndex + i] = txt;
                  }
                }
              }
              // skip column if already defined
              while (tmpArray[rowIndex][cellIndex]) { cellIndex++; }
              txt = tmpArray[rowIndex][cellIndex] || cellValues(cellIndex, $cell, []);
              if (notNull(txt)) {
                tmpArray[rowIndex][cellIndex] = txt;
              }
              cellIndex++;
            });
          }
        }
      });
      $.each(tmpArray, function( i, row ){
        if (notNull(row)) {
          // remove ignoredColumns / add onlyColumns
          var newRow = notNull(opts.onlyColumns) || opts.ignoreColumns.length ?
            $.grep(row, function(v, index){ return !ignoredColumn(index); }) : row,

            // remove ignoredColumns / add onlyColumns if headings is not defined
            newHeadings = notNull(opts.headings) ? headings :
              $.grep(headings, function(v, index){ return !ignoredColumn(index); });

          txt = arraysToHash(newHeadings, newRow);
          result[result.length] = txt;
        }
      });
      return result;
    };

    // Run
    var headings = getHeadings(this);
    return construct(this, headings);
  };
})( jQuery );
