window.therapeuticAreas = [
	"Cardiology/Vascular Diseases",
	"Dental and Oral Health",
	"Dermatology",
	"Devices",
	"Endocrinology",
	"Family Medicine",
	"Gastroenterology",
	"Genetic Disease",
	"Healthy Volunteers",
	"Hematology",
	"Hepatology (Liver, Pancreatic, Gall Bladder)",
	"Immunology",
	"Infections and Infectious Diseases",
	"Internal Medicine",
	"Musculoskeletal",
	"Nephrology",
	"Neurology",
	"Nutrition and Weight Loss",
	"Obstetrics/Gynecology (Women’s Health)",
	"Oncology",
	"Ophthalmology",
	"Orthopedics/Orthopedic Surgery",
	"Otolaryngology (Ear, Nose, Throat)",
	"Pediatrics/Neonatology",
	"Pharmacology/Toxicology",
	"Podiatry",
	"Psychiatry/Psychology",
	"Pulmonary/Respiratory Diseases",
	"Rheumatology",
	"Sleep",
	"Trauma (Emergency, Injury, Surgery)",
	"Urology",
	"Vaccines"
];

//97 to 120 -- a to z
window.footnoteSyms = [];
for(i=97;i<121;i++) {
	window.footnoteSyms.push(String.fromCharCode(i));
}
