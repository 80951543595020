soaApp.controller('editUser', ['$scope','$rootScope','$http','close',function ($scope, $rootScope, $http, close) {
	
	$rootScope.modalTitle = 'Edit User Account';
	$scope.modalBodyTemplate = 'editUser';
	
	$http({
		method: 'GET',
		url: '/json/user/'+$rootScope.user_oid
	}).then(({data} = response) => {
      $rootScope.user = data;
      $scope.modalBodyTemplate = 'editUser';
	});
	
	$scope.button_save = 'Save';  
	
	$scope.close = function(result) {
		var thisModel = this.user;
		if (result == 'save') {
	 		$http({
	            method : 'POST',
	            url : '/json/user/save/'+$rootScope.user_oid,
	            data : thisModel
	        }).then(() => {
	        	$('.modal').modal('hide');
	 		});
	 		$rootScope.user = this.user;
	 	}
	 };
	 
	 $scope.options = {
	    toolbar: [
			['style', ['bold', 'italic', 'underline', 'superscript','subscript','clear','ui.sortable']],
			['font', ['strikethrough']],
			['color', ['color']]
	    ]
	  };
	
	 $scope.clearCPT = function() {
		 $rootScope.cpt = [{}];
		 $rootScope.assessment.cpt_code='';
	 }
}]);