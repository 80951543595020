//setting DataTable defaults across app
$.extend( $.fn.dataTable.defaults, {
	"bServerSide":false,
    "bProcessing":true,
    "bStateSave":false,
    "bDeferRender":true,
	"bPaginate" :true,
	"bInfo" :true,
	"iDisplayStart":0,
	"bLengthChange": false,
	"sPaginationType": "full_numbers"
} );


window.filterEndpoint = (colIndex,checked) => {
	searchTerm = checked ? '' : 'x';
	$('#assessmentTable').DataTable().column( colIndex ).search(searchTerm).draw();
}

window.dataTableLangDefaults = () => {
	return {
		"oPaginate": { "sFirst": "<<" , "sLast":">>", "sPrevious": "<" , "sNext":">" },
		"sProcessing": '<div class="loader">Loading...</div>',
		"sInfoEmpty" : ""
	};	
}

window.ep_1 = (arr) => { if ($.inArray('1', arr) >= 0) return 'x'; else return '';}
window.ep_2 = (arr) => { if ($.inArray('2', arr) >= 0) return 'x'; else return '';}
window.ep_3 = (arr) => { if ($.inArray('3', arr) >= 0) return 'x'; else return '';}
window.ep_4 = (arr) => { if ($.inArray('4', arr) >= 0) return 'x'; else return '';}